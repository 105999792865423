import React, { useEffect, useState } from "react";

import "./VideoConference.css";

import Poll from "./Poll";
import { Button, Modal } from "antd";
import { CopyOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import CountDownTimer from "./CountDownTimer";
import {
	createISOTimeString,
	createTZString,
	getCookie,
	isValidHttpURL,
} from "../../utils/commonFunctions";
import moment from "moment";
import { srvTime } from "../../utils/commonApis";
import GroupChat from "../GroupChat/GroupChat";

import GoogleAuth from "../common/GoogleAuth";

const VideoConference = (props) => {
	const { is_moderator, closeMeetingModal, isVideoCall } = props;
	const jitsiContainerId = "jitsi-container-id";
	let CryptoJS = require("crypto-js");
	const socket = props?.socket;

	const [showNetworkingData, setShowNetworkingData] = useState(false);
	const [pollFlag, setPollFlag] = useState(false);
	const [clickCreatePoll, setClickCreatePoll] = useState(false);
	const [clickViewPoll, setClickViewPoll] = useState(false);
	const [isProducerFlag, setIsProducerFlag] = useState(false);
	const [videoConferenceUrl, setVideoConferenceUrl] = useState("");
	const [userName, setUserName] = useState("");
	const [resetTimer, setResetTimer] = useState(false);
	const [isTimerOn, setTimerOn] = useState(false);
	const [shuffleTimeForCurrentUser, setShuffleTimeForCurrentUser] =
		useState(null);

	const [tableName, setTableName] = useState("");

	const [jitsi, setJitsi] = React.useState({});

	const conferenceUrl = useSelector(
		(state) => state?.videoConference?.videoConferenceUrl,
	);

	const shuffleTime = useSelector(
		(state) => state?.videoConference?.shuffleTime,
	);

	const networking_session_id = useSelector(
		(state) => state?.videoConference?.networking_session_id,
	);

	const sessionStartTime = useSelector(
		(state) => state?.videoConference?.sessionStartTime,
	);

	const isPollOptionsVisible = useSelector(
		(state) => state?.videoConference?.isPollOptionsVisible,
	);

	const isFromNetworking = useSelector(
		(state) => state?.videoConference?.isFromNetworking,
	);

	const auditorium_session_id = useSelector(
		(state) => state?.videoConference?.auditorium_session_id,
	);

	const auditorium_configuration_id = useSelector(
		(state) => state?.videoConference?.auditorium_configuration_id,
	);

	const receiver_chat_id = useSelector(
		(state) => state?.videoConference?.receiver_chat_id,
	);

	const showMessageBoard = useSelector(
		(state) => state?.videoConference?.showMessageBoard,
	);

	const stream_url = useSelector((state) => state?.videoConference?.stream_url);
	const stream_key = useSelector((state) => state?.videoConference?.stream_key);

	const [shuffleLoader, setShuffleLoader] = useState(false);
	const [loginUserId, setLoginUserId] = useState(null);
	const [isGroupChatVisible, setIsGroupChatVisible] = useState(false);
	const [roomName, setRoomName] = useState(null);
	const [openGoogleAuthModal, setOpenGoogleAuthModal] = useState({
		googleFlag: false,
		googleEmail: "",
	});

	const [newlyGeneratedStreamKey, setNewlyGeneratedStreamKey] = useState("");

	const dispatch = useDispatch();

	useEffect(() => {
		const ciphertext = getCookie("user_name");
		let bytes = CryptoJS.AES.decrypt(ciphertext, "user_name");
		let user_name = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		setUserName(user_name);

		if (getCookie("user_id")) {
			let cipher_login_user_id = getCookie("user_id");
			let bytes_login_user_id = CryptoJS.AES.decrypt(
				cipher_login_user_id,
				"user_id",
			);
			let jsonparse_user_id = JSON.parse(
				bytes_login_user_id.toString(CryptoJS.enc.Utf8),
			);

			setLoginUserId(jsonparse_user_id);
		}
	}, [CryptoJS.AES]);

	useEffect(() => {
		//Logic to get table link and set shuffle time using socket
		const shuffleTableListener = (userTableLinkData) => {
			console.log("test socket called");
			console.log(
				"test 0 check sess id matches",
				userTableLinkData?.[0]?.networking_session_id === networking_session_id,
			);
			console.log("test 0.1", userTableLinkData);
			if (
				userTableLinkData?.[0]?.networking_session_id === networking_session_id
			) {
				setShuffleLoader(true);
				console.log("test1 table link", userTableLinkData);
				console.log("test2 user id", loginUserId);
				console.log("test3 shuffle time", shuffleTime);
				// dispatch(setUserTableDataFromSocket(userTableLinkData));

				let checkIfUserExist = false;
				userTableLinkData?.forEach((item) => {
					item?.networking_session_table_joinees?.forEach((user) => {
						if (user?.joinee_user_id === loginUserId) {
							console.log(
								user?.joinee_user_id === loginUserId,
								"test4 compare id",
							);

							console.log("test5  user", user);

							console.log(
								"test6 table Link",
								item?.networking_session_table_link,
							);

							const myNode = document.getElementById(jitsiContainerId); //To remove old iframe
							console.log("myNode", myNode);
							myNode.innerHTML = "";

							initialiseJitsi(
								getMeetRoomName(item?.networking_session_table_link),
								userName,
							);

							setVideoConferenceUrl(item?.networking_session_table_link);
							setTableName(
								item?.networking_session_table_link.split("/").pop(),
							);

							setShuffleTimeForCurrentUser({
								minutes: shuffleTime,
								seconds: 0,
							});
							checkIfUserExist = true;
							setTimeout(() => {
								setShuffleLoader(false);
							}, 500);
						}
					});
				});
				console.log("test7 check user exist", checkIfUserExist);
				if (!checkIfUserExist) {
					setTimeout(() => {
						setShuffleLoader(false);
					}, 500);
				}
			}
		};

		socket?.on("shuffleTableUsers", shuffleTableListener);

		// socket.on("initialCountDownTime", initialCountDownTime);

		return () => {
			socket?.off("shuffleTableUsers", shuffleTableListener);
		};
	}, [socket, loginUserId]);

	useEffect(() => {
		//initial time when join table icon is clicked we load this table link url

		let user_name = "";
		if (getCookie("user_name")) {
			const ciphertext = getCookie("user_name");
			let bytes = CryptoJS.AES.decrypt(ciphertext, "user_name");
			user_name = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		}

		setVideoConferenceUrl(conferenceUrl);
		setRoomName(getMeetRoomName(conferenceUrl));

		initialiseJitsi(getMeetRoomName(conferenceUrl), user_name);

		if (conferenceUrl) {
			setTableName(conferenceUrl?.split("/").pop());
		}
	}, [conferenceUrl]);

	useEffect(() => {
		if (networking_session_id && shuffleTime && sessionStartTime) {
			//Logic to get diff between start time of session and current time

			getShuffleTimeBasedOnCurrentTime();
			setTimerOn(true);
		}
	}, [shuffleTime]);

	useEffect(() => {
		setShowNetworkingData(isFromNetworking);
	}, [isFromNetworking]);

	useEffect(() => {
		if (props?.isProducerCurator && auditorium_session_id) {
			callStreamingAccountCreateApi();
		}
	}, [props?.isProducerCurator, auditorium_session_id]);

	const callStreamingAccountCreateApi = async () => {
		let start_date_str =
			moment().format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss");

		let currentDateTime = createISOTimeString(start_date_str);

		let body = {
			auditorium_session_id: auditorium_session_id,
			current_date_time: currentDateTime,
		};
		const response = await ApiCall(
			"POST",
			body,
			"/admin/youtube/streaming/create",
			null,
			true,
		);

		if (response?.data?.code === 200) {
			if (response?.data?.data?.stream_key)
				setNewlyGeneratedStreamKey(response?.data?.data?.stream_key);
			else toast.error(response?.data?.message);
		} else {
			toast.error(response?.data?.message);
		}
	};

	const getShuffleTimeBasedOnCurrentTime = async () => {
		let intervalTime = sessionStartTime;

		// let currentTime = moment(new Date().toUTCString());

		var st = srvTime();

		let serverTimeConversionsToLocal = createTZString(st);

		let localizedTimeZone = moment(
			serverTimeConversionsToLocal.format("HH:mm:ss A"),
			"HH:mm:ss A",
		);

		//
		//

		let currentTime = localizedTimeZone;

		//
		//

		do {
			intervalTime = moment(intervalTime).add(shuffleTime * 60, "seconds");
		} while (currentTime.isSameOrAfter(intervalTime));

		let countDownMinutes = moment
			.duration(intervalTime.diff(currentTime))
			.minutes();
		let countDownSeconds = moment
			.duration(intervalTime.diff(currentTime))
			.seconds();

		// setShuffleTimeForCurrentUser({
		//   minutes: countDownMinutes,
		//   seconds: countDownSeconds,
		// });
		setShuffleTimeForCurrentUser({
			minutes: shuffleTime,
			seconds: 0,
		});
	};

	// const [jitsi, setJitsi] = React.useState({});

	const loadJitsiScript = () => {
		let resolveLoadJitsiScriptPromise = null;

		const loadJitsiScriptPromise = new Promise((resolve) => {
			resolveLoadJitsiScriptPromise = resolve;
		});

		const script = document.createElement("script");
		script.src = `${process.env.REACT_APP_JITSI_URL}external_api.js`;
		script.async = true;
		script.onload = resolveLoadJitsiScriptPromise;
		document.body.appendChild(script);

		return loadJitsiScriptPromise;
	};

	function getMeetRoomName(str) {
		let string1 = str?.split("//")?.pop();

		let string2 = string1?.split("/");

		let string3 = string2?.slice(1)?.join("/");

		return string3;
	}

	const initialiseJitsi = async (roomName = null, displayName = "") => {
		if (!window.JitsiMeetExternalAPI) {
			await loadJitsiScript();
		}
		let originalModeratorId;
		const _jitsi = new window.JitsiMeetExternalAPI(
			// "meet-grv8.dev.brainvire.net/",
			// "meet.staging.gravit8.io",
			"video.gravit8.io",
			{
				// roomName: "Event/101/150/375/zoneDemoRoom",
				roomName: roomName,
				parentNode: document.getElementById(jitsiContainerId),
				userInfo: {
					displayName: displayName,
				},
				onReadyToClose: () => {
					console.log("Meeting is ready to close!");
					// Add your custom logic here
				},
			},
		);

		_jitsi.on("readyToClose", () => {
			console.log("Meeting is ready to closeeee!");
			setTimeout(() => {
				_jitsi.executeCommand("stopRecording", {
					mode: "stream",
					transcription: false,
				});
				console.log("Live stream stopped.");
			}, 1000);
			closeMeetingModal(false);
			socket?.emit(
				"liveStreamHasEnded",
				JSON.stringify({ auditorium_session_id }),
			);
			if (isVideoCall) {
				console.log("closeVideoCallNotification");
				let test123 = socket.emit(
					"closeVideoCallNotification",
					JSON.stringify({
						chat_id: receiver_chat_id,
					}),
				);

				let test1234 = socket.emit(
					"closeVideoCallPopup",
					JSON.stringify({
						chat_id: receiver_chat_id,
					}),
				);
				console.log("closeVideoCallNotification isEmitted", test123);
			}
		});

		_jitsi.on("recordingStatusChanged", (linkObj) => {
			console.log("recordingStatusChanged", linkObj);
			if (linkObj.on) {
				//Emit Socket event
				setTimeout(() => {
					_jitsi.executeCommand("stopRecording", {
						on: false,
						mode: "stream",
						transcription: true,
					});
					_jitsi.executeCommand("toggleVideo");
				}, 5000);
				socket?.emit(
					"liveStreamHasStarted",
					JSON.stringify({ auditorium_session_id }),
				);
			}
		});

		// Listen for participant leave events
		_jitsi.on("videoConferenceLeft", (participant) => {
			console.log("Conference Left: ", participant);
			const { id } = participant;
			if (id === originalModeratorId) {
				console.log("Original moderator has left. Stopping livestream...");
				_jitsi.executeCommand("stopRecording", { mode: "stream" });
			}
		});

		_jitsi.on("participantRoleChanged", (event) => {
			console.log(event);
			const { id, role } = event;
			if (role === "moderator" && !originalModeratorId) {
				// Store the original moderator's ID
				originalModeratorId = id;
				console.log(`Original moderator identified: ${id}`);
			}
		});

		setJitsi(_jitsi);
	};

	React.useEffect(() => {
		// initialiseJitsi();

		return () => jitsi?.dispose?.();
	}, [jitsi]);

	return (
		<>
			{showNetworkingData && !shuffleLoader ? (
				<h5 className="fs-pink d-flex">
					{shuffleTime > 0 ? (
						<span className="mx-2">Shuffle timer :</span>
					) : null}
					{isTimerOn ? (
						<CountDownTimer
							hoursMinSecs={{
								minutes: shuffleTimeForCurrentUser?.minutes,
								seconds: shuffleTimeForCurrentUser?.seconds,
							}}
							repeatShuffleTime={shuffleTime}
							showShuffleLoader={() => {
								setShuffleLoader(true);
							}}
							hideShuffleLoader={() => {
								setShuffleLoader(false);
							}}
							resetTimerToShuffleTime={() => {
								setShuffleTimeForCurrentUser({
									//Logic to repeat countdown after 1st shuffle
									minutes: shuffleTime,
									seconds: 0,
								});
							}}
							fetchTableLink={() => {
								// callGetTableLinkAPI();
							}}
						/>
					) : null}
					<span
						className={
							shuffleTime > 0
								? "video-conference-table-name"
								: "video-conference-table-name-with-no-shuffle"
						}
					>
						{"Table " + tableName}
					</span>
				</h5>
			) : null}
			{shuffleLoader && showNetworkingData ? (
				<div className="w-100 h-100 flex_center shuffle_loader">
					<h1 className="fs-pink">Reshuffling Tables</h1>
				</div>
			) : null}
			<div
				id={jitsiContainerId}
				style={{
					height: `${
						pollFlag || isGroupChatVisible
							? isGroupChatVisible
								? "47%"
								: "56%"
							: "94%"
					}`,
					width: "100%",
					overflow: "hidden",
					position: "relative",
					display: shuffleLoader ? "none" : "block",
				}}
			>
				{/* <iframe
              title="jitsi-meet-box"
              allow="camera; microphone; display-capture; autoplay; clipboard-write"
              // src="https://meet.jit.si/InAppTest"
              // src="https://meet-grv8.dev.brainvire.net/Event/65/92/jitsi1"
              src={`${videoConferenceUrl}#userInfo.displayName="${userName}"`}
              style={{
                height: showNetworkingData
                  ? "96%"
                  : pollFlag || isGroupChatVisible
                  ? isGroupChatVisible
                    ? "88%"
                    : "88%"
                  : "94%",
                width: "100%",
              }}
            /> */}
			</div>
			{!showNetworkingData && isPollOptionsVisible ? (
				<div className="video-conference-btns-container mt-1">
					<>
						{showMessageBoard ? (
							<Button
								className="video-conference-message-btn-streaming"
								onClick={() => {
									setIsGroupChatVisible(!isGroupChatVisible);
									setClickViewPoll(false);
									setClickCreatePoll(false);
									setPollFlag(false);
								}}
							>
								<div className="flex_center">
									Message
									{isGroupChatVisible ? (
										<UpOutlined className="ms-2" />
									) : (
										<DownOutlined className="ms-2" />
									)}
								</div>
							</Button>
						) : null}

						{isProducerFlag ? (
							<Button
								className="video-conference-create-poll-btn"
								onClick={() => {
									let flag = clickCreatePoll;

									if (flag) {
										setClickCreatePoll(false);
										setClickViewPoll(false);
										setIsGroupChatVisible(false);
										setPollFlag(false);
									} else {
										setClickViewPoll(false);
										setIsGroupChatVisible(false);

										setClickCreatePoll(true);
									}
								}}
							>
								<div className="flex_center">
									Create Poll
									{clickCreatePoll ? (
										<UpOutlined className="ms-2" />
									) : (
										<DownOutlined className="ms-2" />
									)}
								</div>
							</Button>
						) : null}
						<Button
							className="video-conference-view-poll-btn"
							onClick={() => {
								let flag = clickViewPoll;
								if (flag) {
									setClickCreatePoll(false);
									setClickViewPoll(false);
									setIsGroupChatVisible(false);
									setPollFlag(false);
								} else {
									setClickViewPoll(true);
									setClickCreatePoll(false);
									setIsGroupChatVisible(false);
								}
							}}
						>
							<div className="flex_center">
								View Poll
								{clickViewPoll ? (
									<UpOutlined className="ms-2" />
								) : (
									<DownOutlined className="ms-2" />
								)}
							</div>
						</Button>
						{newlyGeneratedStreamKey ? (
							<Button
								className="video-conference-copy-btn"
								onClick={() => {
									navigator.clipboard.writeText(newlyGeneratedStreamKey);
									toast.info("Copied to clipboard");
								}}
							>
								<div className="flex_center">
									Copy Streaming Key to Clipboard
									<CopyOutlined className="ms-2" />
								</div>
							</Button>
						) : null}
					</>
				</div>
			) : null}
			{isGroupChatVisible ? (
				<GroupChat
					socket={socket}
					is_moderator={is_moderator}
					auditorium_session_id={auditorium_session_id}
					auditorium_configuration_id={auditorium_configuration_id}
				/>
			) : null}
			{isPollOptionsVisible ? (
				<Poll
					clickCreatePoll={clickCreatePoll}
					clickViewPoll={clickViewPoll}
					changeClickCreatePollFlag={() => {
						//triggerd when hide poll is called
						setClickCreatePoll(false);
					}}
					changeClickViewPollFlag={() => {
						//triggerd when hide poll is called
						setClickViewPoll(false);
					}}
					changeNetworkingFlag={(flag) => {
						setShowNetworkingData(flag);
					}}
					changePollFlag={(flag) => {
						setPollFlag(flag);
					}}
					changeIsProducerFlag={(flag) => {
						setIsProducerFlag(flag);
					}}
				/>
			) : null}
			{openGoogleAuthModal?.googleFlag ? (
				<Modal
					title={<b>Google Auth</b>}
					visible={openGoogleAuthModal?.googleFlag}
					onOk={() =>
						setOpenGoogleAuthModal({
							googleFlag: false,
							googleEmail: "",
						})
					}
					onCancel={() =>
						setOpenGoogleAuthModal({
							googleFlag: false,
							googleEmail: "",
						})
					}
					footer={[
						<Button
							className="px-3 rounded"
							key="save"
							type="primary"
							onClick={() =>
								setOpenGoogleAuthModal({
									googleFlag: false,
									googleEmail: "",
								})
							}
						>
							Ok
						</Button>,
					]}
					className="openGooglAuthModal"
				>
					<GoogleAuth email={openGoogleAuthModal?.googleEmail} />
				</Modal>
			) : null}
		</>
	);
};

export default VideoConference;

// {isValidHttpURL(videoConferenceUrl) ? (
//   <iframe
//     title="jitsi-meet-box"
//     allow="camera; microphone; display-capture; autoplay; clipboard-write"
//     // src="https://meet.jit.si/InAppTest"
//     // src="https://meet-grv8.dev.brainvire.net/Event/65/92/jitsi1"
//     src={`${videoConferenceUrl}#userInfo.displayName="${userName}"`}
//     style={{ height: "100%", width: "100%" }}
//   />
// ) : (
//   <div className="w-100 h-100 flex_center">
//     <h1 className="fs-pink">Link Not Valid</h1>
//   </div>
// )}
